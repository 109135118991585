import { rem } from 'polished';
import styled, { css } from 'styled-components';

const AddBusinessLinksListItemContainer = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.base.grays.grayLightest};
    display: flex;
    
    padding-top: ${rem(16)};

     ${({ isEditing }) =>
        !isEditing &&
        css`
            padding-bottom: ${rem(16)};
        `}

     ${({ isLast }) =>
        isLast &&
        css`
            border-bottom: none;
        `}


    ${({ isFirst }) =>
        isFirst &&
        css`
            border-top: none;
        `}

    ${({ allowDrag }) =>
        allowDrag &&
        css`
            cursor: grab;
        `}
`;

export default AddBusinessLinksListItemContainer;
