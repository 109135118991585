import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Avatar, Card, Chip, Grid, IconButton, Typography } from '@mui/material';
import { Button } from 'baseline-ui/button';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const EntityLinkRequestReceivedItem = ({
    data,
    onApprove,
    onDeny,
    onDelete,
    showStatus,
    showMessage,
}) => {
    const { requestingEntity, approvalstatus, created, message } = data;

    const statusChip =
        approvalstatus === 'APPROVED' ? (
            <Chip
                label={<FormattedMessage id="addBusiness.page.sections.alltypes.accepted.title" />}
                color="success"
            />
        ) : approvalstatus === 'DENIED' ? (
            <Chip
                label={<FormattedMessage id="addBusiness.page.sections.alltypes.denied.title" />}
                color="error"
            />
        ) : null;

    return (
        <Card sx={{ mb: 2, p: 2 }}>
            <Grid container alignItems="center" spacing={2}>
                <Grid item>
                    <Avatar
                        alt={requestingEntity.name}
                        src={requestingEntity.photos?.[0]?.url}
                        variant="rounded"
                        sx={(theme) => ({
                            borderRadius: theme.spacing(2),
                            width: 88,
                            height: 88,
                        })}
                    />
                </Grid>
                <Grid item xs>
                    <Typography variant="h6">{requestingEntity.name}</Typography>
                    <Typography variant="body2" color="text.secondary">
                        {requestingEntity.position || '-'}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {new Date(created).toLocaleDateString()}
                    </Typography>
                </Grid>

                {showStatus && <Grid item>{statusChip}</Grid>}

                {(onApprove || onDeny) && (
                    <Grid item>
                        <Grid container gap={1}>
                            {onApprove && (
                                <Button skin="success" onClick={onApprove}>
                                    <FormattedMessage id="addBusiness.page.sections.alltypes.requests.approve.button.label" />
                                </Button>
                            )}
                            {onDeny && (
                                <Button btnType="outlined" onClick={onDeny}>
                                    <FormattedMessage id="addBusiness.page.sections.alltypes.requests.reject.button.label" />
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                )}
                {onDelete && (
                    <Grid item>
                        <IconButton color="error" onClick={onDelete}>
                            <HighlightOffIcon />
                        </IconButton>
                    </Grid>
                )}
            </Grid>
            {showMessage && message && (
                <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                    {message}
                </Typography>
            )}
        </Card>
    );
};

EntityLinkRequestReceivedItem.propTypes = {
    data: PropTypes.shape({
        requestingEntity: PropTypes.shape({
            name: PropTypes.string.isRequired,
            position: PropTypes.string,
            photos: PropTypes.array,
        }),
        approvalstatus: PropTypes.string.isRequired,
        created: PropTypes.number.isRequired,
        message: PropTypes.string,
    }).isRequired,
    onApprove: PropTypes.func,
    onDeny: PropTypes.func,
    onDelete: PropTypes.func,
    showStatus: PropTypes.bool,
    showMessage: PropTypes.bool,
};

EntityLinkRequestReceivedItem.defaultProps = {
    onApprove: null,
    onDeny: null,
    onDelete: null,
    showStatus: false,
    showMessage: false,
};

export default EntityLinkRequestReceivedItem;
