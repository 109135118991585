import { ModalContext } from 'baseline-ui/modal';
import { ADD_BUSINESS_REQUIRED_INFORMATION } from 'common/addBusinessViewIds';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { isAdminSelector } from 'redux/selectors/authenticationSelectors';
import { isPageAdminSelector } from 'redux/selectors/businessAccessRightsSelectors';
import { businessFormSelector } from 'redux/selectors/businessFormSelectors';
import ModalUnsavedChangesSection from 'screens/BusinessForm/ModalUnsavedChangesSection';
import addBusinessSectionsUtils from './addBusinessSectionsUtils';
import useProcessComplete from './useProcessComplete';
import { GET_BUSINESS_FORM_START } from 'redux/actions/BusinessFormActions';

const useBusinessSectionsNavigation = (hasUnsavedChanges, onSaveCallback) => {
    const intl = useIntl();
    const { showModal } = useContext(ModalContext);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();

    const { type } = useSelector(businessFormSelector);
    const isAdmin = useSelector(isAdminSelector);
    const isPageAdmin = useSelector(isPageAdminSelector);

    const [activeView, setActiveView] = useState({
        sectionId: ADD_BUSINESS_REQUIRED_INFORMATION,
        sectionIndex: 0,
    });
    const [shouldNavigateNext, setShouldNavigateNext] = useState(false);

    const addBusinessSections = useMemo(
        () =>
            addBusinessSectionsUtils.renderAddBusinessSections({
                intl,
                type,
                isSuperAdmin: isAdmin,
                isPageAdmin: isPageAdmin,
            }) || [],
        [intl, type, isAdmin, isPageAdmin],
    );
    const lastSectionIndex = addBusinessSections.length - 1;

    const isComplete = useProcessComplete({ processNames: ['POST_BUSINESS_FORM_PROCESS'] });

    const updateUrlParams = (sectionId) => {
        const urlParams = new URLSearchParams(location.search);
        urlParams.set('section', sectionId);
        navigate(`${location.pathname}?${urlParams.toString()}`);
    };

    const handleSetActiveView = (indexDelta) => {
        setActiveView((prevState) => {
            const newIndex = prevState.sectionIndex + indexDelta;
            if (newIndex < 0 || newIndex > lastSectionIndex) {
                return prevState;
            }
            const newView = {
                sectionIndex: newIndex,
                sectionId: addBusinessSections[newIndex]?.id,
            };
            updateUrlParams(newView.sectionId);
            return newView;
        });
    };

    const handleBackNavigation = () => {
        if (hasUnsavedChanges) {
            showModal(ModalUnsavedChangesSection, {
                onSaveCallback,
                onCompleteCallback: () => {
                    handleSetActiveView(-1);
                    dispatch({
                        type: GET_BUSINESS_FORM_START,
                        payload: id,
                    });
                },
            });
        } else {
            handleSetActiveView(-1);
        }
    };

    const handleNextNavigation = async () => {
        if (hasUnsavedChanges) {
            onSaveCallback();
            setShouldNavigateNext(true);
        } else {
            handleSetActiveView(1);
        }
    };

    useEffect(() => {
        if (isComplete && shouldNavigateNext) {
            handleSetActiveView(1);
            setShouldNavigateNext(false);
        }
    }, [isComplete, shouldNavigateNext]);

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const activeSection = urlParams.get('section');
        if (activeSection && !hasUnsavedChanges) {
            const sectionIndex = addBusinessSections.findIndex(
                (section) => section.id === activeSection,
            );
            setActiveView({
                sectionId: activeSection,
                sectionIndex: sectionIndex !== -1 ? sectionIndex : 0,
            });
        }
    }, [location.search, addBusinessSections]);

    return {
        handleBackNavigation,
        handleNextNavigation,
        activeView,
        setActiveView: (view) => {
            setActiveView(view);
            updateUrlParams(view.sectionId);
        },
        lastSectionIndex,
    };
};

export default useBusinessSectionsNavigation;
