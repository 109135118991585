import { rem } from 'polished';
import styled from 'styled-components';

const NavigationFooterContainer = styled.div`
    align-items: center;
    margin-bottom: -95px;
    display: flex;
    padding: ${rem(16)};
    margin-top: ${rem(32)};
`;

export default NavigationFooterContainer;
