import React, { useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { GET_BUSINESS_SUBCATEGORY_FILTERS_START } from '../../redux/actions/CategoriesActions';
import PropTypes from 'prop-types';
import CategoriesFields from './CategoriesFields';

const CategoriesForm = ({
    onSubmit,
    categories,
    subcategories,
    selectedCategories,
    handleCategoryChange,
    handleSubcategoryChange,
    categoryId,
    subcategoryId,
    subcategoryFilters,
    actionMode,
    onCancelEdit
}) => {
    const dispatch = useDispatch();
    const setFieldValueRef = useRef(null);
    const initialValues = {
        category: '',
        subcategory: '',
        filter: {},
    };

    useEffect(() => {
        if (setFieldValueRef.current) {
            setFieldValueRef.current('category', categoryId);
            setFieldValueRef.current('subcategory', '');
            setFieldValueRef.current('filter', {});
        }
    }, [categoryId]);

    useEffect(() => {
        if (!categoryId || !setFieldValueRef.current) {
            return;
        }

        setFieldValueRef.current('subcategory', subcategoryId ? subcategoryId : '');
        setFieldValueRef.current('filter', {});

        if (subcategoryId) {
            dispatch({
                type: GET_BUSINESS_SUBCATEGORY_FILTERS_START,
                payload: { subcategoryId, categoryId },
            });
        }
    }, [subcategoryId]);

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ setFieldValue }) => {
                setFieldValueRef.current = setFieldValue;
                return (
                    <Form>
                        <CategoriesFields
                            subcategoryFilters={subcategoryFilters}
                            handleSubcategoryChange={handleSubcategoryChange}
                            handleCategoryChange={handleCategoryChange}
                            categories={categories}
                            subcategories={subcategories}
                            selectedCategories={selectedCategories}
                            onSubmit={onSubmit}
                            actionMode={actionMode}
                            onCancelEdit={onCancelEdit}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};
CategoriesForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
        }),
    ).isRequired,
    subcategories: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
        }),
    ),
    selectedCategories: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
            filters: PropTypes.arrayOf(
                PropTypes.shape({
                    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                    name: PropTypes.string.isRequired,
                }),
            ).isRequired,
        }),
    ),
    handleCategoryChange: PropTypes.func.isRequired,
    handleSubcategoryChange: PropTypes.func.isRequired,
    categoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    subcategoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    subcategoryFilters: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
            filters: PropTypes.arrayOf(
                PropTypes.shape({
                    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                    name: PropTypes.string.isRequired,
                }),
            ).isRequired,
        }),
    ),
    actionMode: PropTypes.oneOf(['new', 'edit']),
    onCancelEdit: PropTypes.func.isRequired,
};

CategoriesForm.defaultProps = {
    subcategories: [],
    selectedCategories: [],
    categoryId: null,
    subcategoryId: null,
    subcategoryFilters: [],
    actionMode: "new",
};

export default CategoriesForm;
