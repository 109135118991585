import { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminLeftAsideTemplate from 'components/pageLayout/templates/AdminLeftAsideTemplate';
import PageHeader from 'components/pageLayout/PageHeader';
import { useIntl } from 'react-intl';
import { ThemeContext } from 'styled-components';
import { ModalContext } from 'baseline-ui/modal';
import { useNavigate, useParams } from 'react-router-dom';
import FavePageAsideMyBusiness from 'components/favePageAside/FavePageAsideMyBusiness';
import useBusinessSectionsNavigation from 'utils/useBusinessSectionsNavigation';
import FavePageMainMyBusiness from 'components/favePageMain/FavePageMainMyBusiness';
import useProcessLoader from 'utils/useProcessLoader';
import useProcessComplete from 'utils/useProcessComplete';
import useProcessError from 'utils/useProcessError';
import useAddBusinessValid from 'utils/useAddBusinessValid';
import { businessFormSelector } from 'redux/selectors/businessFormSelectors';
import useBlockerOnUnsavedChangesBusiness from 'utils/useBlockerOnUnsavedChangesBusiness';
import {
    POST_BUSINESS_FORM_PROCESS,
    POST_BUSINESS_FORM_START,
    RESET_BUSINESS_FORM_START,
    SET_BUSINESS_FORM_DATA_START,
} from '../redux/actions/BusinessFormActions';
import CommonWrapper from './CommonWrapper';
import ModalSaveBusinessConfirmSubmit from './BusinessForm/ModalSaveBusinessConfirmSubmit';
import FavePageStepper from './BusinessForm/FavePageStepper';

const AddFavePage = () => {
    const { entityType } = useParams();

    const intl = useIntl();
    const theme = useContext(ThemeContext);
    const navigate = useNavigate();
    const { showModal } = useContext(ModalContext);
    const dispatch = useDispatch();
    const { hasUnsavedChanges, type } = useSelector(businessFormSelector);

    const isLoading = useProcessLoader({
        processNames: [POST_BUSINESS_FORM_PROCESS],
        withOverlay: true,
    });

    useProcessComplete({ processNames: [POST_BUSINESS_FORM_PROCESS], withFeedback: false });
    useProcessError({ processNames: [POST_BUSINESS_FORM_PROCESS], withFeedback: true });

    const { isSaveValid } = useAddBusinessValid();

    useEffect(() => {
        dispatch({ type: RESET_BUSINESS_FORM_START });

        if (entityType) {
            dispatch({
                type: SET_BUSINESS_FORM_DATA_START,
                payload: { type: entityType },
            });
        }
    }, [dispatch, entityType]);

    const handleOnClickSectionsCallback = () => {
        dispatch({
            type: POST_BUSINESS_FORM_START,
            payload: {
                navigate,
                showModal,
            },
        });
    };

    const handleOnClickSubmitCallback = useCallback(() => {
        if (!isSaveValid) {
            return showModal(ModalSaveBusinessConfirmSubmit);
        }

        return dispatch({
            type: POST_BUSINESS_FORM_START,
            payload: {
                navigate,
                showModal,
            },
        });
    }, [isSaveValid]);

    const { handleBackNavigation, activeView } = useBusinessSectionsNavigation();

    const { sectionId } = activeView;

    useBlockerOnUnsavedChangesBusiness(hasUnsavedChanges, handleOnClickSubmitCallback);

    if (type !== entityType) {
        return null;
    }

    return (
        <CommonWrapper>
            <AdminLeftAsideTemplate
                bgStart={theme.site.admin.bgStart}
                bgEnd={theme.site.admin.bgEnd}
                banner={
                    <PageHeader
                        title={intl.formatMessage({
                            id: `addFavePage.${type}.page.title`,
                        })}
                    />
                }
                aside={
                    <FavePageAsideMyBusiness
                        currentSectionId={sectionId}
                        onClick={() =>
                            showModal(ModalSaveBusinessConfirmSubmit, {
                                onClickSubmitCallback: handleOnClickSectionsCallback,
                            })
                        }
                    />
                }
                main={
                    <>
                        <FavePageStepper
                            onStepClick={() =>
                                showModal(ModalSaveBusinessConfirmSubmit, {
                                    onClickSubmitCallback: handleOnClickSectionsCallback,
                                })
                            }
                            currentStep={activeView?.sectionIndex || 0}
                        />
                        <FavePageMainMyBusiness
                            currentSectionId={sectionId}
                            handleBackNavigation={handleBackNavigation}
                            disableBackNavigation
                            handleNextNavigation={handleOnClickSubmitCallback}
                        />
                    </>
                }
            />
        </CommonWrapper>
    );
};

export default AddFavePage;
