import React, {useState} from 'react';
import { Field } from 'formik';
import {Select, MenuItem, Button, InputLabel} from '@mui/material';
import ArrowIcon from 'images/arrow_down_gray.svg';
import PropTypes from "prop-types";

const CategoriesSelect = ({ name, fullWidth, displayEmpty, children,  ...props }) => {
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };
    return (
        <>
            {props.label &&(
                <InputLabel className="selectLabelItem" id={name}>{props.label}</InputLabel>
            )}
            <Field
                as={Select}
                labelId={name}
                name={name}
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                fullWidth={fullWidth}
                defaultValue=""
                variant="standard"
                style={{ height: 'auto', padding: '0.5rem 1rem' }}
                IconComponent={() => (
                    <img src={ArrowIcon} alt="Arrow icon" />
                )}
                displayEmpty={displayEmpty}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    PaperProps: {
                        style: {
                            maxHeight: 600,
                            borderRadius: 28,
                        },
                    },
                }}
                {...props}
            >
                {children}
                {props.multiple && (
                    <MenuItem className="stickyMenuItem">
                        <Button onClick={handleClose}>Done</Button>
                    </MenuItem>
                )}
            </Field>
        </>

    );
};

CategoriesSelect.propTypes = {
    name: PropTypes.string.isRequired,
    fullWidth: PropTypes.bool,
    displayEmpty: PropTypes.bool,
    children: PropTypes.node,
};
export default CategoriesSelect;
