import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import FormCheckboxLabel from './checkbox/FormCheckboxLabel';
import RadioIndicator from './radio/RadioIndicator';
import CheckboxInput from './checkbox/CheckboxInput';
import CheckboxLabel from './checkbox/CheckboxLabel';
import formPropTypes from '../prop-types/formPropTypes';

class FormRadio extends Component {
    constructor(props) {
        super(props);

        this.state = {
            focused: false,
        };
    }

    handleFocus = () => {
        this.setState({ focused: true });
    };

    handleBlur = (event) => {
        const { field } = this.props;
        this.setState({ focused: false });
        field.onBlur(event);
    };

    handleChange = (event) => {
        const { field } = this.props;
        field.onChange(event);
    };

    render() {
        const { id, field, form, value, sizeValue, disabled, skin, children } = this.props;
        const { focused } = this.state;
        const newValue = getIn(form.values, field.name);
        const checked = String(newValue) === String(value);

        return (
            <FormCheckboxLabel sizeValue={sizeValue}>
                <CheckboxInput
                    id={id}
                    name={field.name}
                    type="radio"
                    value={value}
                    checked={checked}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    disabled={disabled}
                    skin={skin}
                />
                <RadioIndicator
                    focused={focused}
                    checked={checked}
                    disabledValue={disabled}
                    sizeValue={sizeValue}
                    skin={skin}
                />
                <CheckboxLabel disabledValue={disabled} sizeValue={sizeValue} skin={skin}>
                    {children}
                </CheckboxLabel>
            </FormCheckboxLabel>
        );
    }
}

FormRadio.propTypes = {
    id: PropTypes.string.isRequired,
    field: formPropTypes.formikField.isRequired,
    form: formPropTypes.formikForm.isRequired,
    value: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    sizeValue: PropTypes.oneOf(['default', 'lg']),
    children: PropTypes.node,
    skin: formPropTypes.formInputSkin,
};

FormRadio.defaultProps = {
    sizeValue: 'default',
    disabled: false,
    children: null,
    skin: 'dark',
};

export default FormRadio;
