import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Field, Form, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { SET_BUSINESS_FORM_DATA_START } from 'redux/actions/BusinessFormActions';
import useIsFormDirtyFeedback from 'utils/useIsFormDirtyFeedback';
import { businessFormSelector } from 'redux/selectors/businessFormSelectors';
import { FieldContainer, FormTextarea } from '../../baseline-ui/form';
import { Col, Row } from 'react-bootstrap';

const AddBusinessAdditionalInformationForm = () => {
    const { type } = useSelector(businessFormSelector);
    const { values, dirty } = useFormikContext();
    const dispatch = useDispatch();
    const intl = useIntl();

    useEffect(() => {
        dispatch({
            type: SET_BUSINESS_FORM_DATA_START,
            payload: values,
        });
    }, [values]);

    useIsFormDirtyFeedback({
        isDirty: dirty,
        dependencies: [values],
        processName: SET_BUSINESS_FORM_DATA_START,
    });

    return (
        <Form noValidate>
            <Row>
                <Col xxs={30}>
                    <FieldContainer>
                        <Field
                            component={FormTextarea}
                            label={intl.formatMessage({
                                id: `${type}.page.sections.additionalInformation.form.about.label`,
                            })}
                            name="description"
                            id="description"
                            rows={8}
                            maxLength={2000}
                            showCharacterCount={true}
                        />
                    </FieldContainer>
                </Col>
            </Row>
        </Form>
    );
};

export default AddBusinessAdditionalInformationForm;
