import { rem } from 'polished';
import styled from 'styled-components';

const FavePageMainSectionNavigationActionsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: ${rem(32)};
    margin-bottom: ${rem(32)};
`;

export default FavePageMainSectionNavigationActionsContainer;
