import { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Checkbox,
    IconButton,
    FormControlLabel,
} from '@mui/material';
import { Button } from 'baseline-ui/button';
import { H5, H6 } from '../../baseline-ui/typography';
import AddBusinessCategoriesFilterListItemContainer from '../../components/AddBusinessCategoriesList/AddBusinessCategoriesFilterListItemContainer';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/DeleteForeverTwoTone';

const DataTable = ({
    data,
    onRemoveFilter,
    onRemoveCategory,
    onRowSelection,
    onRemoveSubcategory,
    selectedCategoryId,
}) => {
    const [selectedRow, setSelectedRow] = useState(null);

    const handleRowSelect = (categoryId, subcategoryId) => {
        const isSelected =
            selectedRow?.categoryId === categoryId && selectedRow?.subcategoryId === subcategoryId;

        const newSelection = isSelected ? {} : { categoryId, subcategoryId };
        setSelectedRow(newSelection);
        onRowSelection(newSelection);
    };

    useEffect(() => {
        if (!selectedCategoryId && selectedRow?.categoryId) {
            const newSelection = {};
            setSelectedRow(newSelection);
        }
    }, [selectedCategoryId]);

    const renderTableRows = () => {
        const rows = [];

        for (const categoryId in data) {
            const category = data[categoryId];

            // Iterate through subcategories
            Object.keys(category.subcategories).forEach((subcategoryId) => {
                const subcategory = category.subcategories[subcategoryId];
                const isSelected =
                    selectedRow?.categoryId === categoryId &&
                    selectedRow?.subcategoryId === subcategoryId;

                const filters = subcategory.filters.map((filter) => (
                    <span key={filter.id} style={{ display: 'inline-flex', alignItems: 'center' }}>
                        <AddBusinessCategoriesFilterListItemContainer>
                            <H6 color="primary">{filter.name}</H6>
                            <Button
                                icon="x"
                                btnType="link"
                                size="sm"
                                onClick={() => onRemoveFilter(filter.id)}
                            />
                        </AddBusinessCategoriesFilterListItemContainer>
                    </span>
                ));

                rows.push(
                    <TableRow
                        key={`${categoryId}-${subcategoryId}`}
                        sx={{
                            backgroundColor: isSelected ? 'lightgrey' : 'inherit',
                        }}
                    >
                        <TableCell>
                            <div style={{ display: 'inline-flex', alignItems: 'center', gap: 6 }}>
                                <IconButton
                                    color="error"
                                    disabled={isSelected}
                                    aria-label="delete"
                                    onClick={() => onRemoveSubcategory(subcategory.id)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                                {onRowSelection && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isSelected}
                                                onChange={() =>
                                                    handleRowSelect(categoryId, subcategoryId)
                                                }
                                            />
                                        }
                                        label={<H5 color="primary">{category.name}</H5>}
                                    />
                                )}

                                {!onRowSelection && <H5 color="primary">{category.name}</H5>}
                            </div>
                        </TableCell>
                        <TableCell>
                            <H6 color="primary">{subcategory.name}</H6>
                        </TableCell>
                        <TableCell>{filters}</TableCell>
                    </TableRow>,
                );
            });

            // If no subcategories, render the category directly
            if (Object.keys(category.subcategories).length === 0) {
                const isSelected =
                    selectedRow?.categoryId === categoryId && !selectedRow?.subcategoryId;

                rows.push(
                    <TableRow
                        key={categoryId}
                        sx={{
                            backgroundColor: isSelected ? 'lightgrey' : 'inherit',
                        }}
                    >
                        <TableCell>
                            <div style={{ display: 'inline-flex', alignItems: 'center', gap: 6 }}>
                                <IconButton
                                    color="error"
                                    disabled={isSelected}
                                    aria-label="delete"
                                    onClick={() => onRemoveCategory(category.id)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                                {onRowSelection && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isSelected}
                                                onChange={() => handleRowSelect(categoryId, null)}
                                            />
                                        }
                                        label={<H5 color="primary">{category.name}</H5>}
                                    />
                                )}

                                {!onRowSelection && <H5 color="primary">{category.name}</H5>}
                            </div>
                        </TableCell>
                        <TableCell>
                            <H5 color="primary" textAlign="center">
                                -
                            </H5>
                        </TableCell>
                        <TableCell>
                            {category?.filters?.map((filter) => (
                                <span
                                    key={filter.id}
                                    style={{ display: 'inline-flex', alignItems: 'center' }}
                                >
                                    <AddBusinessCategoriesFilterListItemContainer>
                                        <H6 color="primary">{filter.name}</H6>
                                        <Button
                                            icon="x"
                                            btnType="link"
                                            size="sm"
                                            onClick={() => onRemoveFilter(filter.id)}
                                        />
                                    </AddBusinessCategoriesFilterListItemContainer>
                                </span>
                            ))}
                        </TableCell>
                    </TableRow>,
                );
            }
        }

        return rows;
    };

    return (
        <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
            <Box sx={{ overflow: 'auto' }}>
                <Box sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
                    <Table>
                        <TableBody>{renderTableRows()}</TableBody>
                    </Table>
                </Box>
            </Box>
        </TableContainer>
    );
};

DataTable.propTypes = {
    data: PropTypes.objectOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
            subcategories: PropTypes.objectOf(
                PropTypes.shape({
                    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                    name: PropTypes.string.isRequired,
                    filters: PropTypes.arrayOf(
                        PropTypes.shape({
                            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                                .isRequired,
                            name: PropTypes.string.isRequired,
                        }),
                    ).isRequired,
                }),
            ).isRequired,
            filters: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                    name: PropTypes.string.isRequired,
                }),
            ),
        }),
    ).isRequired,
    onRemoveFilter: PropTypes.func.isRequired,
    onRemoveSubcategory: PropTypes.func.isRequired,
    onRemoveCategory: PropTypes.func.isRequired,
    onRowSelection: PropTypes.func.isRequired,
};

export default DataTable;
