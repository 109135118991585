import { media } from 'baseline-ui/layout';
import { rem } from 'polished';
import styled from 'styled-components';

const AdminBusinessManagerStatusContainer = styled.div`
    margin-top: ${rem(16)};
    padding-top: ${({ theme }) => rem(theme.site.page.sectionNavigation.height + 32)};

    ${media.md`
        padding-left: ${rem(32)};
        padding-right: ${rem(32)};
        padding-top: ${rem(40)};
`}
`;

export default AdminBusinessManagerStatusContainer;
