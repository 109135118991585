import {
  GET_BUSINESS_ACCESS_RIGHTS_SUCCESS,
  RESET_BUSINESS_ACCESS_RIGHTS_START,
  GET_PAGE_ACCESS_RIGHTS_USER_SUCCESS
} from "../actions/BusinessAccessRightsActions";

const BusinessAccessRightsReducer = (state = { users: [], role: '' }, action) => {
  const { type, payload } = action;
  switch (type) {
    case RESET_BUSINESS_ACCESS_RIGHTS_START: {
      return {
        ...state,
        users: []
      };
    }
    case GET_BUSINESS_ACCESS_RIGHTS_SUCCESS: {
      return {
        ...state,
        users: payload
      };
    }

    case GET_PAGE_ACCESS_RIGHTS_USER_SUCCESS: {
      return {
        ...state,
        role: payload[0].permissionType,
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default BusinessAccessRightsReducer;
