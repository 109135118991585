import { media } from 'baseline-ui/layout';
import styled from 'styled-components';

const NavigationFooterContent = styled.div`
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    width: 100%;

    ${media.lg`
        align-items: center;
        flex-direction: row;
    `}
`;

export default NavigationFooterContent;
