import { GetUserList } from 'api';
import { Button } from 'baseline-ui/button';
import { Image } from 'baseline-ui/helpers';
import { Col, Row } from 'baseline-ui/layout';
import { H4, H5, P, Small } from 'baseline-ui/typography';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import CommonWrapper from 'screens/CommonWrapper';

const MAX_COUNT = 10;

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [pageNum, setPageNum] = useState(0);
    const [userFavePages, setUserFavePages] = useState({});

    useEffect(() => {
        const asyncRequests = async () => {
            const [data] = await GetUserList({ pageNum, maxCount: MAX_COUNT });
            console.log(data);

            setUsers(data);
        };

        asyncRequests();
    }, [pageNum]);

    return (
        <CommonWrapper>
            <Col display="flex" flexDirection="column" padding={20} justifyContent="center">
                <H4>Users List</H4>

                <Col>
                    {users?.length === 0 && <Col>No users</Col>}
                    {users.map((user) => (
                        <Col
                            display="flex"
                            flexDirection="column"
                            justifyContent={'space-between'}
                            key={user._id}
                            style={{
                                borderBottomWidth: 1,
                                borderBottomColor: 'black',
                                borderBottomStyle: 'solid',
                            }}
                        >
                            <Row justifyContent={'space-between'}>
                                <Col>
                                    <Row>
                                        First Name:{' '}
                                        {user.firstName ? user.firstName : 'No First Name provided'}
                                    </Row>
                                    <Row>
                                        Last Name:
                                        {user.lastName ? user.lastName : 'No Last Name provided'}
                                    </Row>
                                    <Row>
                                        User name:{' '}
                                        {user.username ? user.username : 'No username provided'}
                                    </Row>
                                    <Row>
                                        Email: {user.email ? user.email : 'No email provided'}
                                    </Row>
                                </Col>
                                <Col alignItems="center" display="flex">
                                    {user.favepages.length > 0 && !userFavePages[user._id] && (
                                        <Button
                                            btnType="outlined"
                                            size="sm"
                                            onClick={() => {
                                                setUserFavePages((prev) => ({
                                                    ...prev,
                                                    [user._id]: user.favepages,
                                                }));
                                            }}
                                        >
                                            Show FavePages ({user.favepages?.length})
                                        </Button>
                                    )}
                                    {userFavePages[user._id] && (
                                        <Button
                                            btnType="outlined"
                                            size="sm"
                                            onClick={() => {
                                                setUserFavePages((prev) => ({
                                                    ...prev,
                                                    [user._id]: null,
                                                }));
                                            }}
                                        >
                                            Hide FavePages
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                {userFavePages[user._id]?.length === 0 && (
                                    <Col>
                                        <H4>No FavePages for this business</H4>
                                    </Col>
                                )}
                                <Col flex={1}>
                                    {userFavePages[user._id]?.map((favePage) => (
                                        <Row
                                            style={{ border: '1px solid black' }}
                                            key={favePage._id}
                                            justifyContent={'flex-start'}
                                            padding={10}
                                            margin={10}
                                        >
                                            <Col xs="5">
                                                {favePage?.photos?.[0]?.url && (
                                                    <Image
                                                        src={favePage?.photos?.[0]?.url}
                                                        height={150}
                                                    />
                                                )}
                                                {!favePage?.photos?.[0]?.url && (
                                                    <div
                                                        style={{
                                                            height: 150,
                                                            width: 150,
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        No Image
                                                    </div>
                                                )}
                                            </Col>
                                            <Col xs="25">
                                                <H5>
                                                    {favePage.name} ({favePage._id})
                                                </H5>

                                                <P>
                                                    Desctiption:{' '}
                                                    {isEmpty(favePage.description)
                                                        ? 'Not provided'
                                                        : favePage.description}
                                                </P>
                                                <Small>{favePage.formattedAddress}</Small>
                                            </Col>
                                        </Row>
                                    ))}
                                </Col>
                            </Row>
                        </Col>
                    ))}
                </Col>
                <Row isFullHeight={false}>
                    {pageNum > 0 && (
                        <Button
                            btnType="link"
                            onClick={() => {
                                setPageNum(pageNum - 1);
                            }}
                        >
                            Previous Page
                        </Button>
                    )}

                    {users?.length !== 0 && (
                        <Button
                            btnType="link"
                            onClick={() => {
                                setPageNum(pageNum + 1);
                            }}
                        >
                            Next Page
                        </Button>
                    )}
                </Row>
            </Col>
        </CommonWrapper>
    );
};

export default UserList;
