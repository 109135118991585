import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { Button as ButtonElement } from 'baseline-ui/button';
import { ProgressiveImg } from 'baseline-ui/helpers';
import { EntityPickerData } from 'components/EntityPicker/components';
import { rem, transparentize } from 'polished';
import { GaIcon } from 'baseline-ui/icon';
import {
    BUSINESS_APPROVAL_STATUS_APPROVED,
    BUSINESS_APPROVAL_STATUS_DENIED,
    ENTITY_TYPE_ICONS,
} from 'common/consts';
import StatusTag from 'components/StatusTag';
import { useIntl } from 'react-intl';
import { Card } from '@mui/material';

const Button = styled(ButtonElement)`
    width: 100%;
`;

const RequestItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.5rem;
`;

const RequestDataContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const ImageContainer = styled.div`
    display: inline-flex;
    background-color: ${({ theme }) => transparentize(0.6, theme.base.colors.light)};
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.lg)};
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: 0 1rem 0 0.3rem;
    overflow: hidden;
    height: 5.5rem;
    width: 5.5rem;
`;

const StatusActionContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const EntityLinkRequestSentItem = (props) => {
    const intl = useIntl();
    const {
        data,
        data: { _id, approvalstatus, requestedEntity },
        onRemove,
        onResend,
    } = props;
    const { name, photos, formattedAddress, type } = requestedEntity;
    const image = photos.length > 0 ? photos[0].url : null;
    const theme = useContext(ThemeContext);

    return (
        <Card sx={{ mb: 2, p: 2 }}>
            <RequestItemContainer>
                <RequestDataContainer>
                    <ImageContainer>
                        {image ? (
                            <ProgressiveImg image={image} />
                        ) : (
                            <GaIcon
                                icon={ENTITY_TYPE_ICONS[type]}
                                size={64}
                                color={theme.base.colors.muted}
                            />
                        )}
                    </ImageContainer>
                    <EntityPickerData>
                        <div>
                            <strong>{name}</strong>
                        </div>
                        <div>{formattedAddress}</div>
                    </EntityPickerData>
                </RequestDataContainer>
                <StatusActionContainer>
                    <StatusTag status={approvalstatus} />
                    {approvalstatus === BUSINESS_APPROVAL_STATUS_DENIED ? (
                        <Button onClick={() => onResend?.(data)}>
                            {intl.formatMessage({
                                id: 'addBusiness.page.sections.sentRequests.resend.button.label',
                            })}
                        </Button>
                    ) : null}
                    {approvalstatus === BUSINESS_APPROVAL_STATUS_APPROVED ? (
                        <Button onClick={() => onRemove?.(data)}>
                            {intl.formatMessage({
                                id: 'addBusiness.page.sections.sentRequests.remove.button.label',
                            })}
                        </Button>
                    ) : null}
                </StatusActionContainer>
            </RequestItemContainer>
        </Card>
    );
};

EntityLinkRequestSentItem.propTypes = {
    data: PropTypes.object.isRequired,
    onRemove: PropTypes.func,
    onResend: PropTypes.func,
};

EntityLinkRequestSentItem.defaultProps = {
    data: {},
};

export default EntityLinkRequestSentItem;
